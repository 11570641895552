.test .slick-next {
  right: 33px;
  position: absolute;
}
.test .slick-prev {
  left: 3% !important;
  z-index: 1;
}
.test .slick-arrow:before {
  font-size: 39px;
}
.test .slick-prev:before,
.test .slick-next:before {
  color: white !important;
}
@media (max-width: 768px) {
  .test .slick-arrow:before {
    font-size: 29px;
  }
  .test .slick-next {
    right: 20px;
    position: absolute;
  }
  .test .slick-prev {
    left: 1.6% !important;
    z-index: 1;
  }
}

.sucess-message {
  color: green;
}
