/* Global Styles */
.services-page {
    font-family: Arial, sans-serif;
    background-color: #f4f4f9;
    color: #333;
    padding: 40px;
}

h1 {
    text-align: center;
    font-size: 2.5rem;
    color: #2c3e50;
    margin-bottom: 40px;
}

.service-category {
    margin-bottom: 50px;
}

h2 {
    font-size: 2rem;
    color: #34495e;
    border-bottom: 2px solid #3498db;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.service-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.service-item {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 250px;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.service-item:hover {
    transform: translateY(-10px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

h3 {
    font-size: 1.2rem;
    margin-bottom: 10px;
}

select {
    padding: 8px;
    margin: 10px 0;
    border-radius: 5px;
    border: 1px solid #ccc;
    width: 100%;
}

.book-now-btn {
    display: inline-block;
    padding: 10px 20px;
    background-color: #3498db;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.book-now-btn:hover {
    background-color: #2980b9;
}

/* Unique Category Styles */

/* SMS Services */
.service-category.sms-services {
    background-color: #fefefe;
}

.service-category.sms-services h2 {
    color: #771308;
    border-color: #771308;
}

/* Digital Services */
.service-category.digital-services {
    background-color: #f9f9f9;
}

.service-category.digital-services h2 {
    color: #27ae60;
    border-color: #27ae60;
}

/* Website Design */
.service-category.website-design {
    background-color: #f1f1f1;
}

.service-category.website-design h2 {
    color: #df4802;
    border-color: #df4802;
}

/* Email Marketing */
.service-category.email-marketing {
    background-color: #fafafa;
}

.service-category.email-marketing h2 {
    color: #2980b9;
    border-color: #2980b9;
}

/* Other Services */
.service-category.other-services {
    background-color: #fcfcfc;
}

.service-category.other-services h2 {
    color: #f39c12;
    border-color: #f39c12;
}

/* Domain & Web Hosting */
.service-category.domain-web-hosting {
    background-color: #eff2f5;
}

.service-category.domain-web-hosting h2 {
    color: #2c3e50;
    border-color: #2c3e50;
}
