@import url(https://fonts.googleapis.com/css?family=Raleway:100,200,300,regular,500,600,700,800,900,100italic,200italic,300italic,italic,500italic,600italic,700italic,800italic,900italic);

.quotation {
  font-family: auto;
}
.gradient-blue {
  background: rgb(14, 42, 71);
  background: linear-gradient(
    180deg,
    rgba(14, 42, 71, 1) 45%,
    rgba(4, 69, 143, 1) 99%
  );
}
.partners img {
  aspect-ratio: 3/2;
  object-fit: contain;
  margin: auto;
}
.shadow-card {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.shadow-card:hover {
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
  transition: 0.2s;
  /* scale: 1.06; */
  transform: translateY(-6px);
}
.slick-prev:before,
.slick-next:before {
  color: black !important;
}
.slick-dots li.slick-active button:before {
  color: #04458f !important;
}
.feature-plan {
  background: url("./assets/sms/f1.jpg"), rgba(173, 63, 63, 0.9);
  background-size: cover;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}
.bg-gradient {
  /* background: linear-gradient(135deg, #0575e6 1%, #00f260 100%); */
  background: rgb(131, 58, 180);
  background: linear-gradient(
    38deg,
    rgba(131, 58, 180, 1) 0%,
    rgba(253, 29, 29, 1) 50%,
    rgba(252, 176, 69, 1) 100%
  );
}
.btn-gradient {
  background: linear-gradient(79deg, #045cb4 29%, #00bf4c 123%);
}
.price-gradient {
  background: linear-gradient(135deg, #0575e6 1%, #00f260 100%);
}

.price-gradient h1,
.price-gradient h5 {
  color: #fff;
}
.banner-input input,
.banner-input textarea {
  border-bottom: 1px solid #ccc;
  margin-bottom: 10px;
  padding: 10px;
}
.about-bg {
  background: -webkit-linear-gradient(
    -45deg,
    rgba(229, 229, 229, 0.9) 0%,
    rgba(255, 255, 255, 0.9) 100%
  );
}
.about-line::after {
  content: "";
  border-bottom: 2px solid #045cb4;
  display: block;
  width: 45px;
  margin: 1.4rem auto;
}
.service-line::after {
  content: "";
  border-bottom: 2px solid #045cb4;
  display: block;
  width: 45px;
  margin: 1.4rem 0;
}
input:focus,
textarea:focus {
  outline: none;
}
.laptop {
  width: 100%;
  top: 0;
  left: 0;
  background-image: url("assets/laptop.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.2;
}
.header-line {
  border-bottom: 1px solid #fff;
}

.price-input input {
  border: 1px solid #ccc;
  padding: 8px;
  margin-bottom: 10px;
  width: 100%;
}
.b {
  background-size: cover;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
}
.b1 {
  background-image: url("./assets/1.jpg");
}
.b2 {
  background-image: url("./assets/2.jpg");
}
.b3 {
  background-image: url("./assets/3.jpg");
}
.b4 {
  background-image: url("./assets/4.jpg");
}
.b5 {
  background-image: url("./assets/3.jpg");
}
.about-text-bg {
  background: url("./assets/laptop.jpg") no-repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.bg-contact {
  background-image: url("./assets/received/contact.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.overlay-con {
  background-color: rgba(0, 0, 0, 0.5);
}
/* //button */
.container {
  /* width: 400px;
  height: 400px; */
  position: relative;
  /* left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center; */
}
.center {
  width: 120px;
  height: 40px;
  position: relative;
}

.btn {
  width: 120px;
  height: 40px;
  cursor: pointer;
  background: transparent;
  border: 1px solid #91c9ff;
  outline: none;
  transition: 1s ease-in-out;
}

svg {
  position: absolute;
  left: 0;
  top: 0;
  fill: none;
  stroke: #fff;
  stroke-dasharray: 150 480;
  stroke-dashoffset: 150;
  transition: 1s ease-in-out;
}

.btn:hover {
  transition: 1s ease-in-out;
  background: #4f95da;
  color: white;
}

.btn:hover svg {
  stroke-dashoffset: -480;
}

.btn span {
  color: #000;
  font-size: 18px;
  font-weight: 400;
}
