/* FAQPage.css */
.faq-container {
    max-width: 900px;
    margin: 0 auto;
    padding: 40px 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .faq-title {
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 30px;
    position: relative;
  }
  
  .faq-title::after {
    content: "";
    position: absolute;
    width: 80px;
    height: 3px;
    background-color: #007bff;
    left: 50%;
    transform: translateX(-50%);
    bottom: -10px;
  }
  
  .faq-list {
    margin-top: 20px;
  }
  
  .faq-item {
    background-color: #fff;
    margin-bottom: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: all 0.3s ease;
  }
  
  .faq-item.active {
    background-color: #f1f8ff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  }
  
  .faq-question {
    padding: 20px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.1rem;
    font-weight: 500;
    color: #333;
    transition: background-color 0.3s ease;
  }
  
  .faq-question:hover {
    background-color: #f1f8ff;
  }
  
  .toggle-icon {
    font-size: 1.5rem;
    color: #007bff;
    transition: transform 0.3s ease;
  }
  
  .toggle-icon.rotate {
    transform: rotate(180deg);
  }
  
  .faq-answer {
    padding: 20px;
    background-color: #f9f9f9;
    font-size: 1rem;
    color: #555;
    border-top: 1px solid #e0e0e0;
  }
  
  /* Banner.css */
.banner-container {
    position: relative;
    height: 50vh; /* Full screen height */
    background-image: url('https://files.ekmcdn.com/b61f4c/resources/design/faq-banner-image.jpg'); /* Placeholder image for testing */
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    font-family: 'Arial', sans-serif;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.3); /* Optional: Darken the background */
  }
  
  .banner-button {
    padding: 15px 25px;
    font-size: 1rem;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    text-transform: uppercase;
    transition: background-color 0.3s ease;
    display: inline-block; /* Ensure the link behaves like a button */
    text-decoration: none; /* Remove underline */
    text-align: center;
  }
  
  .banner-button a {
    color: white; /* Ensure the link has the same color as the button */
    text-decoration: none; /* Remove the default link underline */
  }
  
  .banner-button:hover {
    background-color: #0056b3; /* Darker shade for hover effect */
  }


.banner-title {
    font-size: 3rem; /* Adjust the size of the title */
    font-weight: bold;
    margin-bottom: 20px;
    color: white;
  }