
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Arial', sans-serif;
  background-color: #f4f7fa;
}

.mt-28 {
  margin-top: 7rem;
}


.bg-contact {
  background-image: url('https://your-image-url.com'); /* Set your banner image URL */
  background-size: cover;
  background-position: center;
  color: white;
  padding: 100px 20px;
  text-align: center;
  position: relative;
}

.bg-contact h1 {
  font-size: 3.5rem;
  font-weight: 800;
  text-transform: uppercase;
  margin-bottom: 20px;
  letter-spacing: 2px;
}

.bg-contact h2 {
  font-size: 1.3rem;
  margin-bottom: 30px;
  font-weight: 400;
  line-height: 1.6;
}

.bg-contact .overlay-con {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
  z-index: 1;
}

/* Contact Form and Branches Container */
.contact-container {
  display: flex;
  justify-content: space-between;
  padding: 60px 30px;
  margin-top: 3rem;
  gap: 40px;
  flex-wrap: wrap;
}

/* Branches Section (Accordion) */
.contact-left {
  width: 100%;
  background-color: #f8f8f8; /* Light background color for the branches section */
  padding: 25px;
  border-radius: 12px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
  transform: translateY(0);
  transition: transform 0.3s ease-in-out;
}

.contact-left:hover {
  transform: translateY(-10px); /* Hover effect */
}

.contact-left h1 {
  font-size: 2.2rem;
  margin-bottom: 20px;
  color: #333;
  font-weight: 700;
}

/* Accordion Style */
.accordion {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 10px;
}

.accordion-item {
  border-bottom: 1px solid #ddd;
  margin-bottom: 1rem; /* Add space between each accordion item */
}

.accordion-item:last-child {
  border-bottom: none;
}

.accordion-button {
  background-color: orange;
  border: 1px solid orange;
  padding: 10px;
  width: 100%;
  text-align: left;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.accordion-button:hover {
  background-color: darkorange;
}

.accordion-content {
  display: none;
  padding: 10px;
  background-color: red;
  border: 1px solid red;
  border-top: none;
  border-radius: 5px;
}

.accordion-button.active + .accordion-content {
  display: block;
}

/* Form Section (Right) */
.contact-right {
  width: 48%;
  background: linear-gradient(135deg, lightblue, white);
  padding: 35px;
  border-radius: 12px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
  color: black;
  transition: transform 0.3s ease-in-out;
}

.contact-right:hover {
  transform: translateY(-10px); /* Hover effect */
}

.contact-right h1 {
  font-size: 2.2rem;
  margin-bottom: 20px;
  font-weight: 600;
}

.contact-right input,
.contact-right textarea {
  width: 100%;
  padding: 1rem;
  margin-bottom: 1.2rem;
  border-radius: 8px;
  border: 1px solid #ddd;
  font-size: 1rem;
  box-sizing: border-box;
  transition: border-color 0.3s;
}

.contact-right input:focus,
.contact-right textarea:focus {
  border-color: black;
  outline: none;
}

/* Submit Button */
.contact-right .btn-gradient {
  background-color: darkorange;
  border: none;
  padding: 0.8rem 2rem;
  color: white;
  font-size: 1.1rem;
  font-weight: bold;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.contact-right .btn-gradient:hover {
  background-color: black;
}

/* Map Section - 3 Maps Side by Side */
.map-section {
  margin-top: 4rem;
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

/* Overall layout of the contact container */
.contact-container {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem; /* Add some space between the sections */
  justify-content: space-between;
  margin-top: 3rem; /* Adjust as needed */
}

/* Branches Section */
.contact-left {
  flex: 1 1 48%; /* Takes up 50% of the container width */
  max-width: 48%; /* Prevents it from growing beyond 50% */
  background-color: #f8f8f8; /* Light background color for the branches section */
  padding: 1.5rem;
  border-radius: 8px;
}

/* Accordion Styles */
.accordion-item {
  margin-bottom: 1rem; /* Add space between each accordion item */
}

.accordion-button {
  background-color: orange;
  border: 1px solid orange;
  padding: 10px;
  width: 100%;
  height: auto;
  text-align: left;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.accordion-button:hover {
  background-color: darkorange;
}

.accordion-content {
  display: none;
  padding: 10px;
  background-color: red;
  border: 1px solid red;
  border-top: none;
  border-radius: 5px;
}

/* Checkbox text */
.terms-checkbox {
  color: #333; /* Ensure text color is visible */
  margin-left: 10px; /* Adjust the spacing from the checkbox */
}

.terms-checkbox a {
  color: black;
}

.flex.items-center.mt-2 {
  align-items: flex-start; /* Align the checkbox and label properly */
}

/* Adjusting the placement of "I agree..." text */
#agreeTerms {
  margin-left: 10px;
}

/* Add a little margin to the right for better visual balance */
label[for="agreeTerms"] {
  display: inline-block;
  margin-left: 10px;
  text-align: left; /* Align to the left */
  font-size: 0.875rem; /* Slightly smaller font size */
}

/* Styling input, textarea, and buttons */
input, textarea {
  width: 100%;
  padding: 0.5rem;
  margin-top: 10px;
  border: 1px solid black;
  border-radius: 4px;
}

/* Optional: Add spacing between the buttons and inputs */
.otp-section, .btn-gradient {
  margin-top: 15px;
}

.otp-btn, .verify-otp-btn {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.otp-btn:hover, .verify-otp-btn:hover {
  background-color: #0056b3;
}

/* Additional styling for error and success messages */
.error-message, .success-message {
  color: red;
  margin-top: 10px;
}

.success-message {
  color: green;
}

.accordion-content.active {
  display: block;
}

.error-message, .success-message {
  color: red;
  margin-top: 10px;
}

.success-message {
  color: green;
}

/* Map Section */
.map-section iframe {
  width: 100%;
  height: 300px;
  border: none;
  border-radius: 12px;
  transition: transform 0.3s ease;
  flex: 1;  /* Allow equal sizing for all maps */
}

.map-section iframe:hover {
  transform: scale(1.05); /* Zoom effect on hover */
}

/* Responsive Design */
@media (max-width: 1024px) {
  .contact-container {
      flex-direction: column;
      gap: 20px;
  }

  .contact-left {
  width: auto;
  height: 40px;
  }
  .contact-right {
      width: auto;
      height: 40px;
  }

  .map-section {
      flex-direction: column;
  }
}

@media (max-width: 768px) {
  .bg-contact h1 {
      font-size: 2.5rem;
  }

  .bg-contact h2 {
      font-size: 1.1rem;
  }

  .contact-left,
  .contact-right {
      padding: 20px;
  }

  /* Map Section */
.map-section {
  margin-top: 4rem;
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.map-container {
  flex: 1;
  border-radius: 12px;
  overflow: hidden;
  position: relative;
}

.map-container iframe {
  width: 100%;
  height: 250px;
  border: none;
  transition: transform 0.3s ease;
}

.map-container iframe:hover {
  transform: scale(1.05); /* Zoom effect on hover */
}

/* Responsive Design */
@media (max-width: 1024px) {
  .map-section {
    flex-direction: column;
    gap: 20px;
  }

  .map-container {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .map-container iframe {
    height: 200px; /* Adjust map height for smaller screens */
  }
}

/* For better alignment and spacing */
.terms-checkbox {
  display: flex;
  align-items: center;
  font-size: 0.875rem; /* Adjust the font size */
}

.terms-checkbox a {
  color: black;
}

.terms-checkbox .ml-2 {
  margin-left: 8px; /* Spacing between checkbox and text */
}




}
