.rcs-container {
    font-family: 'Arial', sans-serif;
    
    background-color: #f9f9f9;
    max-width: auto;
    margin: 0 auto;
}

.rcs-header {
    text-align: center;
    margin-bottom: 40px;
}

.rcs-header h1 {
    font-size: 2.5rem;
    color: #333;
    font-weight: 600;
}

.rcs-header p {
    font-size: 1.2rem;
    color: #555;
    margin-top: 10px;
}

.rcs-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    margin: 40px;
}

.rcs-info {
    font-size: 1.1rem;
    color: #666;
    line-height: 1.6;
}

.rcs-image img {
    width: 100%;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.features-list {
    list-style: none;
    padding: 0;
    font-size: 1.1rem;
    line-height: 1.8;
}

.features-list li {
    margin-bottom: 12px;
    color: #444;
}

.features-list li::before {
    content: '✔';
    color: #4CAF50;
    margin-right: 8px;
}

.rcs-features {
    margin-bottom: 40px;
}

.rcs-features h2 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 20px;
    font-weight: 600;
}

.benefits-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 30px;
    justify-content: space-between;
}

.benefit-item {
    text-align: center;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.benefit-item:hover {
    transform: translateY(-10px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

.benefit-item img {
    max-width: 100px;
    border-radius: 50%;
    margin-bottom: 20px;
}

.benefit-item h3 {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 10px;
}

.benefit-item p {
    font-size: 1rem;
    color: #777;
}

.rcs-footer {
    text-align: center;
    margin-top: 60px;
    font-size: 1rem;
    color: #777;
}

.rcs-footer p {
    font-size: 1.1rem;
    font-weight: 500;
    color: #333;
}

/* Header and Main Heading */
.rcs-header h1 {
    font-size: 3rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: 20px;
}

.main-heading {
    text-align: center;
    font-size: 1.2rem;
    font-weight: 600;
    color: #555;
    margin-top: 10px;
}

/* Side Heading Style */
.side-heading {
    font-size: 2rem;
    font-weight: 600;
    color: #333;
    margin-bottom: 20px;
}

/* Paragraph Styling with Space Between Lines */
.info-paragraph {
    font-size: 1.1rem;
    line-height: 1.8;
    color: #666;
    margin-bottom: 30px;
}

/* Key Features Styling */
.rcs-features {
    margin-top: 50px;
}

.features-heading {
    font-size: 2.5rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: 20px;
}

.features-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    text-align: center;
}

.feature-item {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.feature-item h3 {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 15px;
}

.feature-item p {
    font-size: 1.1rem;
    color: #555;
}

/* Benefits Section */
.benefits-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
}

.benefit-item {
    text-align: center;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.benefit-item:hover {
    transform: translateY(-10px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

.benefit-image {
    max-width: 120px;
    border-radius: 50%;
    margin-bottom: 15px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.benefit-item h3 {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 10px;
}

.benefit-item p {
    font-size: 1rem;
    color: #777;
}

/* Add space at the top of the page */
.rcs-container {
    padding-top: 50px; /* Add some top padding for space */
}

/* Ensure visibility and styling of the header */
.rcs-header {
    padding: 20px;
    background-color: #f5f5f5;
    border-bottom: 1px solid #ddd;
}

/* Ensure RCS Messaging heading is visible */
.rcs-header h1 {
    font-size: 3rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: 20px;
    color: #333; /* Ensure the text color is visible */
}

/* Bold and centered text for the "Next-generation" heading */
.main-heading {
    text-align: center;
    font-size: 1.2rem;
    font-weight: 600;
    color: #555;
    margin-top: 10px;
}

/* Add space at the top of the page */
.rcs-header p {
    font-size: 1.2rem;
    font-weight: bold;
    color: #555;
    margin-top: 15px;
}

/* Add space between menu bar and header */
.rcs-container {
    padding-top: 80px; /* Increase space from the menu bar */
}

/* Header styling with reduced font size */
.rcs-header {
    padding: 20px;
    background-color: #f5f5f5;
    border-bottom: 1px solid #ddd;
    margin-bottom: 20px;  /* Add margin below the header */
}

/* Reduced font size for the "RCS Messaging" header */
.rcs-header h1 {
    font-size: 2.5rem; /* Decrease font size */
    font-weight: 700;
    text-align: center;
    margin-bottom: 20px;
    color: #333;
}

/* Bold and centered text for the "Next-generation" heading */
.main-heading {
    text-align: center;
    font-size: 1.2rem;
    font-weight: 600;
    color: #555;
    margin-top: 10px;
}

/* Styling for the sub-page links */
.sub-page-link {
    display: inline-block;
    background-color: #4CAF50;
    padding: 10px 20px;
    color: white;
    font-weight: 600;
    text-decoration: none;
    margin-top: 15px;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.sub-page-link:hover {
    background-color: #45a049;
}

/* For feature section */
.features-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.feature-item {
    flex: 1 1 45%;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.feature-item h3 {
    font-size: 1.5rem;
    color: #333;
}

.feature-item p {
    font-size: 1rem;
    color: #777;
}

/* Benefit item box */
.benefit-item {
    text-align: center;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    margin-bottom: 30px;
}

.benefit-item:hover {
    transform: translateY(-10px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

.benefit-item img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 20px;
}

/* Styling for the Trusted Customers Section */
.trusted-customers {
    background-color: #f5f5f5;
    padding: 40px 20px;
    text-align: center;
    margin-bottom: 40px;
}

.trusted-customers h2 {
    font-size: 2rem;
    color: #333;
    font-weight: 600;
    margin-bottom: 20px;
}

.trusted-customers p {
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 40px;
    line-height: 1.6;
}

.customers-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px;
}

.customer-item {
    width: 150px;  /* Set a fixed width for the customer logos */
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 10px;
}

.customer-item img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain; /* To ensure the images are scaled properly */
}

.rcs-accordion
{ 
margin: 40px;
    
}


.accordion-header {
    background-color: #f1f1f1;
    padding: 10px;
    text-align: left;
    font-size: 16px;
    border: 1px solid #ccc;
    cursor: pointer;
    width: 100%;
    display: block;
}

.accordion-content {
    display: none;
    padding: 10px;
    background-color: #f9f9f9;
    border-top: 1px solid #ddd;
}

.accordion-content.show {
    display: block;
}

.pricing-container {
    padding: 20px;
    text-align: center;
}

.pricing-heading {
    font-size: 24px;
    margin-bottom: 20px;
}

.pricing-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.pricing-table th, .pricing-table td {
    border: 1px solid #ddd;
    padding: 12px 15px;
    text-align: center;
}

.pricing-table th {
    background-color: #f2f2f2;
    font-weight: bold;
}

.pricing-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.pricing-table td {
    font-size: 14px;
    color: #333;
}


/* Add this to your CSS file (RCSMessaging.css or a separate file) */
.buy-button {
    background-color: #007bff;
    color: white;
    padding: 8px 16px;
    border-radius: 4px;
    text-decoration: none;
    text-align: center;
}

.buy-button:hover {
    background-color: #0056b3;
}
